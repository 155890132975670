/**
 * Returns runtime environment variable if set.
 * Runtime environment is configured in `src/index.html.ejs` inside window.env.
 * They are configured using placeholders as in window.env = { A : '$E_TICKET_' }.
 *
 * @param {string} variableName - The variable name.
 * @returns {string} - The variable value.
 */
export function getEnvironmentVariable(variableName) {
  if (
    window.env?.[variableName]
    && window.env[variableName] !== `$${process.env.ENV_PREFIX}${variableName}`
  ) {
    return window.env[variableName];
  }

  return null;
}

/**
 * Returns the redirect uri.
 *
 * @param {string} url - The initial URL.
 * @param {string} substr - The string used for for splitting.
 * @returns {string} - Redirect uri.
 */
function getRdirectUri(url, substr) {
  const indexOfSubstr = url.indexOf(substr);
  return indexOfSubstr !== -1 ? url.substring(0, indexOfSubstr) : url;
}

/**
 * @returns {string} - Config env.
 */
const getConfigEnv = () => {
  let json = null;

  if (window.location.href.includes('localhost')) {
    json = {
      vapidPublicKey: getEnvironmentVariable('PUBLIC_VAPID_KEY'),
    };
  } else {
    const request = new XMLHttpRequest();
    request.open('GET', `${window.location.origin}/config/env`, false);
    request.send();
    json = JSON.parse(request.responseText);
  }

  return json;
};

/**
 * @returns {string} - Config am.
 */
function getConfigAM() {
  let json = null;

  if (window.location.href.includes('localhost')) {
    json = {
      host: getEnvironmentVariable('AUTH_URL'),
      clientId: getEnvironmentVariable('AUTH_CLIENT_ID'),
      redirectURI: `${document.location.href}`,
    };

    if (json.host) {
      json.host = json.host.substring(8, json.host.lastIndexOf('/'));
    }
  } else {
    const request = new XMLHttpRequest();
    request.open('GET', `${window.location.origin}/config/am`, false);
    request.send();
    json = JSON.parse(request.responseText);
  }

  // calculating the redirect uri for preview, localhost and another features(develop, QLF etc ..)
  json.redirectURI = `${document.location.href}`;
  json.redirectURI = getRdirectUri(json.redirectURI, 'login-from-am');
  json.redirectURI = getRdirectUri(json.redirectURI, '/#/');

  return json;
}

export const configAM = getConfigAM();
export const configEnv = getConfigEnv();

export default {
  app: {
    name: process.env.npm_package_name,
    version: process.env.npm_package_version,
  },

  api: {
    endpoint: getEnvironmentVariable('API_ENDPOINT') || '',
  },

  auth: {
    url: `https://${configAM.host}/boulanger`,
    clientId: configAM.clientId,
    redirectUri: configAM.redirectURI,
  },

  notifications: {
    endpoint: getEnvironmentVariable('NOTIFICATIONS_API_ENDPOINT') || '',
    publicKey: configEnv.vapidPublicKey,
  },
};

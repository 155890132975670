import { NO_DATA } from 'constants/data';
import * as UserDataRepository from 'domain/userData/repository';
import { mapperUserBFFToFront, mapperUserFrontToBFF } from 'utils/userData/userData.mapper';
import { ACTIONS } from './constants';

export const FETCH_USER_LOADING = userId => ({
  type: ACTIONS.FETCH_USER_LOADING,
  payload: {
    userId,
  },
});

export const FETCH_USER_SUCCESS = (userId, userData) => ({
  type: ACTIONS.FETCH_USER_SUCCESS,
  payload: {
    userId,
    userData,
  },
});

export const FETCH_USER_FAILURE = err => ({
  type: ACTIONS.FETCH_USER_FAILURE,
  error: true,
  payload: {
    err,
    userData: NO_DATA,
  },
});

export const fetchUserData = (userId, name) => async (dispatch) => {
  dispatch(FETCH_USER_LOADING(userId));
  try {
    const userData = await UserDataRepository.fetchUserData(userId);
    return dispatch(FETCH_USER_SUCCESS(userId, mapperUserBFFToFront(name, userData)));
  } catch (err) {
    return dispatch(FETCH_USER_FAILURE(err));
  }
};

export const POST_USER_LOADING = userId => ({
  type: ACTIONS.POST_USER_LOADING,
  payload: {
    userId,
  },
});

export const POST_USER_SUCCESS = (userId, userData) => ({
  type: ACTIONS.POST_USER_SUCCESS,
  payload: {
    userId,
    userData,
  },
});

export const POST_USER_FAILURE = err => ({
  type: ACTIONS.POST_USER_FAILURE,
  error: true,
  payload: {
    err,
  },
});

export const postUserData = (userId, userData) => async (dispatch) => {
  dispatch(POST_USER_LOADING(userId));
  try {
    await UserDataRepository.postUserData(userId, mapperUserFrontToBFF(userData));
    return dispatch(POST_USER_SUCCESS(userId, userData));
  } catch (err) {
    return dispatch(POST_USER_FAILURE(err));
  }
};

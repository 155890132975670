import agent from 'services/http/api';

export const fetchUserData = async (userId) => {
  const { body } = await agent.get(`/users/${userId}`);

  return body;
};

export const postUserData = async (userId, userData) => {
  const { body } = await agent.post('/users').send(userData);

  return body;
};

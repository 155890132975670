export default {
  HOME: {
    ROOT: '/',
  },
  TICKET: {
    ROOT: '/ticket/:ticketId',
  },
  ORDER: {
    ROOT: '/order/:saleId',
  },
  USER: {
    ROOT: '/user/:userId',
  },
  AUTH: {
    LOGIN: '/login-from-am',
    LOGOUT: '/logout',
  },
};

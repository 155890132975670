import store from 'redux/store';

const withToken = (req) => {
  const { user } = store.getState().auth.oidc;
  if (user) {
    req.set('Authorization', `${user.token_type} ${user.access_token}`);
    req.set('X-Auth-token', `${user.token_type} ${user.access_token}`);
  }
  return req;
};

export default withToken;
